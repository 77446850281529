import React from 'react';
import introImg from '../../images/intro-img.jpeg';
import firelighter from '../../images/firelighter.jpg';
import firelighter1 from '../../images/firelighter1.jpeg';
import firelighter2 from '../../images/firelighter2.jpeg';
import SingleGallerySlide from '../peat-moss/SingleGallerySlide';

const Firelighter = () => {
  return (
    <div
      className="vermiculite-intro-wrapper pb-3"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div>
            <div
              style={{ width: '140px', height: '140px', borderRadius: '10px' }}
              className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
            >
              <img
                src={firelighter}
                alt="vermiculite"
                className="border-round shadow-3"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Firelighter
                </span>
              </div>
            </div>
            <p className="mt-0 mb-3 text-700 text-xl">
              Vermiculite <span className="green-c">Firelighter</span>
            </p>
            <div className="text-600 font-medium">
              <p className="mb-3">
                Re-usable firelighters that will last you years! Dip the
                firelighter in paraffin, put it into the fire, light it and you
                have your fire. Remove when the fire is lighted, let it cool,
                and put back into paraffin. Up to 15 minutes burn time.
              </p>
            </div>
            <div className="mt-3 w-100">
              <div className="grid">
                <SingleGallerySlide image={firelighter1} />
                <SingleGallerySlide image={firelighter2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Firelighter;
